var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UpdateYearendBouns" },
    [
      _c(
        "Drawer",
        {
          attrs: {
            closable: "",
            "is-mask": false,
            visible: _vm.visible,
            title: "编辑",
            size: "small",
          },
          on: {
            close: function ($event) {
              _vm.visible = false
            },
            onSubmit: _vm.handleOk,
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.row,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "年终奖" } },
                [
                  _c("CbNumber", {
                    attrs: {
                      "decimal-separator": true,
                      precision: "2",
                      "is-fill-zero": true,
                      maxlength: 10,
                      placeholder: "请输入数字",
                    },
                    model: {
                      value: _vm.row.bounsAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.row, "bounsAmount", $$v)
                      },
                      expression: "row.bounsAmount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }